<template>
  <div>
    <div v-show="preData&&role" class="pieChart" id="echarts" ref="echarts">

    </div>
  </div>
</template>

<script>
let elementResizeDetectorMaker = require("element-resize-detector");

export default {
  name: "PieChart",
  data(){
    return{
      chart:null,
      showData:{
        title: {
          text: '',
          left: 'center'
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'vertical',
          left: 'left'
        },
        series: [
          {
            name: 'Access From',
            type: 'pie',
            radius: '50%',
            data: [

            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      }
    }
  },
  props: {

    preData : {

    },
    role:{

    }
  },
  mounted() {
    var erd = elementResizeDetectorMaker();
    let that = this;
    erd.listenTo(document.getElementById("echarts"), function () {
      const dom = document.getElementById("echarts");
      dom.setAttribute('echarts_instance','')
      dom.style.width = dom.parentNode.parentNode.clientWidth + "px";
      dom.style.height = 600 + "px";


      if(that.role&&that.preData){
        that.handleData()
        that.initData()
      }

    })
  },
  watch: {
    preData:{
      handler(){
        if(this.role){
          this.handleData()
          this.initData()
        }
      }
    },
    role:{
      handler() {
        if (this.preData) {
          this.handleData();
          this.initData()
        }
      }
    },
  },

  methods:{
    handleData(){
      this.showData.title.text=this.role;
      //清洗数据
      this.showData.series[0].data=[];
        for (let i = 0; i < this.preData.length; i++) {
          let sum = 0;
          let name = this.preData[i].name;
          for (let j = 0; j < this.preData[i].data.length; j++) {
            for(let key in this.preData[i].data[j]){
              if(key==this.role){
                sum+=this.preData[i].data[j][key]
              }
            }
          }
          //默认保留两位精度
          sum = sum.toFixed(2);
          let data = {
            value: sum,
            name: name
          }
          this.showData.series[0].data.push(data)
        }
      },

    initData(){
      let that = this;
      setTimeout(function () {
        console.log(document.getElementById("echarts").style.width);
        console.log(document.getElementById("echarts").style.height);

        //如果已有图表，则销毁
        if(that.chart){
          that.chart.dispose();
        }
        that.chart = that.$echarts.init(that.$refs.echarts);
        // 使用刚指定的配置项和数据显示图表。
        that.chart.setOption(that.showData,true);
      },100)
    }
  },
}
</script>

<style scoped>
.pieChart{
  width: 100%;
  height: 600px;
}
</style>